/* global lazyload */
import 'lazyload';

import Abstract from './abstract';

export default class LazyloadImage extends Abstract {
  init() {
    this.getElement();

    this.lazyloadImage();
  }

  getElement() {
    this.imagesLazyload = document.querySelectorAll('.js-lazyload-image');
  }

  lazyloadImage() {
    lazyload(this.imagesLazyload);
  }
}
