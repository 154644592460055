/* global Swiper */
import Abstract from '../abstract';
import { onSendGTag } from '../module';

const JP_TEXT = {
  swipe: 'スワイプ',
  navigation: 'ナビゲーション',
  list: 'リスト',
  next: '次',
  prev: '前',
  tap: 'タップ',
};

const swiperInit = (parentEl, options) => {
  const container = parentEl.querySelector('.swiper');

  return new Swiper(container, options);
};

const labelForChangePhotoGTag = (type, direction) => {
  const typeLabel = JP_TEXT[type] || type;
  const directionLabel = JP_TEXT[direction] || direction;

  return `${typeLabel}_${directionLabel}`;
};

const onSendClickBackToArticleButtonLinkGTag = (event) => {
  onSendGTag('タップ', {
    event_category: '画像ページ記事に戻る',
    event_label: 'タップ',
    link_url: event.currentTarget.getAttribute('href'),
  });
};

const onSendClickArticleRankingLinkGTag = (event, index) => {
  onSendGTag('タップ', {
    event_category: '画像ページランキング',
    event_label: index,
    link_url: event.currentTarget.getAttribute('href'),
  });
};

const onSendChangePhotoGTag = (type, direction) => {
  if (!type || !direction) {
    return;
  }

  onSendGTag('タップ', {
    event_category: '画像ページ_画像スライド',
    event_label: labelForChangePhotoGTag(type, direction),
  });
};

export default class ArticlePhotoView extends Abstract {
  init() {
    this.getElement();

    this.initSwiper();

    this.addEvent();
  }

  getElement() {
    this.currentImagePerNumberOfPhotos = document.querySelector(
      '.js-article-photo-current-image-per-number-of-images',
    );
    this.articlePhotoCarouselContainer = document.querySelector('.js-article-photo-image-carousel-container');
    this.articlePhotoCarouselNavigationPreviousButton = document.querySelector(
      '.js-article-photo-image-carousel-navigation-prev',
    );
    this.articlePhotoCarouselNavigationNextButton = document.querySelector(
      '.js-article-photo-image-carousel-navigation-next',
    );
    this.articlePhotoBackButtonLinks = document.querySelectorAll('.js-article-photo-image-container-back-button');
    this.articlePhotos = document.querySelector('.js-article-photo-images-list');
    this.articlePhotoRankingLinks = document.querySelectorAll('.js-article-photo-ranking-link');
  }

  getCarouselPhotoId() {
    if (!this.articlePhotoCarouselContainerSwiper) {
      return undefined;
    }

    const { activeIndex, slides } = this.articlePhotoCarouselContainerSwiper;
    const currentArticlePhotoElement = slides[activeIndex];
    return currentArticlePhotoElement.dataset.id;
  }

  // eslint-disable-next-line class-methods-use-this
  getParamsArticlePhotoIds() {
    const { pathname } = window.location;
    const [articleId, photoId] = pathname.match(/(\d+)/gm);

    return [articleId, photoId];
  }

  updateCurrentImagePerNumberOfPhotosContent(currentIndex, totalItems) {
    if (!this.currentImagePerNumberOfPhotos) {
      return;
    }

    this.currentImagePerNumberOfPhotos.innerHTML = `${currentIndex}/${totalItems}`;
  }

  updateArticlePhotoItemStyle(currentArticlePhotoId) {
    if (!this.articlePhotos) {
      return;
    }

    this.articlePhotos.querySelectorAll('.js-article-photo-images-item').forEach((imageElement) => {
      if (imageElement.dataset.id === currentArticlePhotoId) {
        imageElement.classList.add('article-photo-Images_Item-Current');
        return;
      }

      imageElement.classList.remove('article-photo-Images_Item-Current');
    });
  }

  updateCurrentArticlePhotoCarousel(photoId) {
    const { slides } = this.articlePhotoCarouselContainerSwiper || { slides: [] };
    slides.forEach((slide) => {
      const { id, swiperSlideIndex } = slide.dataset || {};
      if (id === photoId && swiperSlideIndex) {
        this.articlePhotoCarouselContainerSwiper.slideToLoop(swiperSlideIndex);
      }
    });
  }

  initSwiper() {
    if (!this.articlePhotoCarouselContainer) {
      return;
    }

    const { currentImageId: currentArticlePhotoId } = this.articlePhotoCarouselContainer.dataset;
    const articlePhotoCarousels = this.articlePhotoCarouselContainer.querySelectorAll(
      '.js-article-photo-image-carousel-slide',
    );
    let currentArticlePhotoCarouselIndex = 0;

    articlePhotoCarousels.forEach((imageElement, imageElementIndex) => {
      if (imageElement.dataset.id === currentArticlePhotoId) {
        currentArticlePhotoCarouselIndex = imageElementIndex;
      }
    });

    this.articlePhotoCarouselContainerSwiper = swiperInit(this.articlePhotoCarouselContainer, {
      slidesPerView: 'auto',
      loop: true,
      centeredSlides: true,
      initialSlide: currentArticlePhotoCarouselIndex,
      navigation: {
        prevEl: '.js-article-photo-image-carousel-navigation-prev',
        nextEl: '.js-article-photo-image-carousel-navigation-next',
      },
      on: {
        init: () => {
          this.articlePhotoCarouselContainer.classList.add('article-photo-ImageCarousel_Container-visible');
          this.updateCurrentImagePerNumberOfPhotosContent(
            currentArticlePhotoCarouselIndex + 1,
            articlePhotoCarousels.length,
          );
          this.updateArticlePhotoItemStyle(currentArticlePhotoId);
        },
        touchEnd: () => {
          if (!this.articlePhotoCarouselContainerSwiper) {
            return;
          }

          const { swipeDirection } = this.articlePhotoCarouselContainerSwiper;
          onSendChangePhotoGTag('swipe', swipeDirection);
        },
        slideChange: () => {
          if (!this.articlePhotoCarouselContainerSwiper) {
            return;
          }

          this.updateArticlePhotoItemStyle(this.getCarouselPhotoId());
          this.updateCurrentImagePerNumberOfPhotosContent(
            this.articlePhotoCarouselContainerSwiper.realIndex + 1,
            articlePhotoCarousels.length,
          );
        },
        slideChangeTransitionEnd: () => {
          const carouselPhotoId = this.getCarouselPhotoId();
          const [paramsArticleId, paramsPhotoId] = this.getParamsArticlePhotoIds();

          if (!carouselPhotoId || !paramsPhotoId || carouselPhotoId === paramsPhotoId) {
            return;
          }

          const { pathname } = window.location;
          const currentPathname = pathname.replace(`photos/${paramsPhotoId}`, `photos/${carouselPhotoId}`);
          window.history.pushState({
            paramsArticleId,
            paramsPhotoId,
          }, '', currentPathname);
        },
      },
    });
  }

  addEvent() {
    if (this.articlePhotoCarouselNavigationPreviousButton) {
      this.articlePhotoCarouselNavigationPreviousButton.addEventListener('click', () => {
        onSendChangePhotoGTag('navigation', 'prev');
      });
    }

    if (this.articlePhotoCarouselNavigationNextButton) {
      this.articlePhotoCarouselNavigationNextButton.addEventListener('click', () => {
        onSendChangePhotoGTag('navigation', 'next');
      });
    }

    if (this.articlePhotos) {
      this.articlePhotos.querySelectorAll('.js-article-photo-images-item').forEach((imageElement) => {
        imageElement.addEventListener('click', (event) => {
          onSendChangePhotoGTag('list', 'tap');
          this.updateCurrentArticlePhotoCarousel(event.currentTarget.dataset.id);
        });
      });
    }

    if (this.articlePhotoBackButtonLinks) {
      this.articlePhotoBackButtonLinks.forEach((element) => {
        element.addEventListener('click', onSendClickBackToArticleButtonLinkGTag);
      });
    }

    this.articlePhotoRankingLinks.forEach((element, index) => {
      element.addEventListener('click', (event) => onSendClickArticleRankingLinkGTag(event, index + 1));
    });

    window.addEventListener('popstate', () => {
      const [, photoId] = this.getParamsArticlePhotoIds();

      this.updateCurrentArticlePhotoCarousel(photoId);
    });
  }
}
