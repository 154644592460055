/* global lazyload */
import 'lazyload';

import Abstract from './abstract';

const lazyloadImage = () => {
  const imagesLazyload = document.querySelectorAll('.js-lazyload-image');

  lazyload(imagesLazyload);
};

const INITIAL_STATE = {
  isRequested: false,
};

export default class LoadMoreView extends Abstract {
  initState() {
    this.state = { ...INITIAL_STATE };
  }

  getElement() {
    this.loadMoreButtons = document.querySelectorAll('.js-load-more-button');
    this.loadMoreContainers = document.querySelectorAll('.js-load-more-container');
  }

  // eslint-disable-next-line class-methods-use-this
  getItemsHTML(type, items) {
    let html = '';
    let baseClassName = '';

    const thumbnailBlockHTML = (item, option) => `
      <div
        class="${option?.className} js-lazyload-image"
        data-src="${item?.thumbnail?.image?.url}"
      ></div>
    `;

    const textHTML = (item, option) => `
      <div class="${option?.className}">${item?.[option.key]}</div>
    `;

    switch (type) {
      case 'popularGame':
        baseClassName = 'games-PopularGamesContainer_Content_List_Item';
        html = items.map((item) => `
          <div class="${baseClassName}">
            <a class="${baseClassName}_Game js-google-analytics-item"
              href="games/${item.id}" data-ga-event-category="無料の人気ゲーム" data-ga-event-label="${item.title}"
            >
              ${thumbnailBlockHTML(item, { className: `${baseClassName}_Game_Thumbnail` })}
              ${textHTML(item, { className: `${baseClassName}_Game_Title`, key: 'title' })}
            </a>
            <div class="${baseClassName}_Company">
              ${textHTML(item, { className: `${baseClassName}_Company_Name`, key: 'display_information' })}
            </div>
          </div>
        `).join('');
        break;

      case 'recommendedGame':
        baseClassName = 'games-RecommendedGamesContainer_Content_List_Item';
        html = items.map((item) => `
          <li class="${baseClassName}">
            <a
              class="${baseClassName}_Link js-google-analytics-item"
              href="games/${item.id}" data-ga-event-category="あなたにおすすめのゲーム" data-ga-event-label="${item.title}"
            >
              ${thumbnailBlockHTML(item, { className: `${baseClassName}_Thumbnail` })}
              <div class="${baseClassName}_Information">
                ${textHTML(item, { className: `${baseClassName}_Title`, key: 'title' })}
                ${textHTML(item, { className: `${baseClassName}_Company`, key: 'display_information' })}
              </div>
            </a>
          </li>
        `).join('');
        break;

      default:
        break;
    }

    return html;
  }

  addEvent() {
    this.loadMoreButtons.forEach((element, index) => element.addEventListener('click', (event) => {
      event.preventDefault();
      this.handleLoadMoreButtonClick(element, this.loadMoreContainers[index]);
    }));
  }

  handleLoadMoreButtonClick(button, container) {
    if (this.state.isRequested) return;

    this.onUpdateState({
      isRequested: true,
    });

    const {
      templateType, location, nextCursor, nextCursorOrder, nextCursorPublishDatetime,
    } = button.dataset;

    // The game's data will be retrieved from the game list page
    const url = window.location.href.replace(/games\/\d+/g, 'games');
    const requestBody = {
      location,
      start_cursor: nextCursor,
      start_cursor_order: nextCursorOrder,
      start_cursor_publish_datetime: nextCursorPublishDatetime,
    };

    fetch(
      `${url}?${new URLSearchParams(requestBody)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const contentType = response.headers.get('Content-Type');
        if (!contentType || !contentType.includes('application/json')) {
          throw new Error(`The response is not JSON. Content-Type: ${contentType}`);
        }

        return response.json();
      })
      .then((data) => {
        const {
          items,
          next_cursor: newNextCursor,
          next_cursor_order: newNextCursorOrder,
          next_cursor_publish_datetime: newCursorPublishDatetime,
        } = data;
        const itemsHTML = this.getItemsHTML(templateType, items);

        if (container) {
          container.insertAdjacentHTML('beforeend', itemsHTML);
          lazyloadImage();
        }

        if (newNextCursor) {
          button.setAttribute('data-next-cursor', newNextCursor);
        }

        if (newNextCursorOrder) {
          button.setAttribute('data-next-cursor-order', newNextCursorOrder);
        }

        if (newCursorPublishDatetime) {
          button.setAttribute('data-next-cursor-publish-datetime', newCursorPublishDatetime);
        }

        if (!(newNextCursor || newNextCursorOrder || newCursorPublishDatetime)) {
          button.remove();
        }

        this.onUpdateState({
          isRequested: false,
        });
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
  }
}
